import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Provider } from '../contexts/context';
import GlobalStyle from '../config/GlobalStyle';
import GlobalHead from '../config/GlobalHead';
import Header from '../modules/navigation/Header';
import Footer from '../modules/footer/Footer';

export default function BlogPost({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Provider>
      <GlobalStyle />
      <GlobalHead blogPost imageSource={frontmatter.image} title={frontmatter.title}/>
      <Header />
      <BackWrapper p={3}>
        <StyledLink to="/blog">← Terug naar blog</StyledLink>
      </BackWrapper>
      <HeroBanner alt={frontmatter.title} src={frontmatter.image} />
      <WrapperOfWrapper justifyContent="center">
        <BlogWrapper p={3}>
          <BlogPostTitle p={3}>
            <Styledh1>{frontmatter.title}</Styledh1>
          </BlogPostTitle>
          <BlogPostContent p={3}>
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
            <Author>Auteur: {frontmatter.author}</Author>
            <Onderwerp>Onderwerpen:</Onderwerp>
            {frontmatter.tags.map((item, index) => (
              <Onderwerp key={index}> {item}</Onderwerp>
            ))}
            <Date>{frontmatter.date}</Date>
          </BlogPostContent>
        </BlogWrapper>
      </WrapperOfWrapper>
      <Footer />
    </Provider>
  );
}

export const pageQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        image
        title
        author
        tags
        date(formatString: "DD/MM/YYYY")
      }
    }
  }
`;

const WrapperOfWrapper = styled(Flex)``;

const HeroBanner = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const BlogWrapper = styled(Box)`
  max-width: 1128px;
  margin-top: -50px;
`;

const BlogPostTitle = styled(Box)`
  background-color: white;
  box-shadow: 0 4px 6px 0 rgba(182, 182, 182, 0.2);
  margin-bottom: 25px;
  text-align: center;
  min-height: 100px;
  border-radius: 4px;
`;

const BlogPostContent = styled(Box)`
  background-color: white;
  box-shadow: 0 4px 6px 0 rgba(182, 182, 182, 0.2);
  margin-bottom: 100px;
  border-radius: 4px;
  p {
    margin-bottom: 20px;
  }
  img {
    width: 100%;
  }
`;

const Date = styled.p`
  text-align: right;
`;

const Styledh1 = styled.h1`
  color: #d26426;
  margin: 0;

  @media only screen and (max-width: 600px) {
    font-size: 1.5em;
  }
`;

const StyledLink = styled(Link)`
  text-align: left;
  &:visited {
    color: #d26426;
  }
`;

const BackWrapper = styled(Flex)`
  max-width: 1128px;
  margin: 0 auto;
`;

const Author = styled.p`
  color: grey;
  padding-top: 30px;
`;

const Onderwerp = styled.p`
  color: grey;
  display: inline;
`;
